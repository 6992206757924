<template>
  <div>
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="$router.back()" alt="" />
        <span>分析详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="page-main">
        <div class="page-main-title">
          <img src="../../assets/img/zhang/zuoxia.png" class="zuoxia">
          <!-- 标题的展示 -->
          <template>
            <div class="title texthidden" v-html="info.title"></div>
          </template>
          <!-- 标题结束 -->
          <img src="../../assets/img/zhang/youshang.png" class="youshang">
        </div>
        <!-- 内容的展示 -->
        <template>
          <div class="page-main-content" v-html="info.content"></div>
        </template>
        <!-- 错误表述 -->
        <template v-if="if_special_misre">
          <errCard :errArray='info.special_misre'></errCard>
        </template>
        <!-- 错别字 -->
        <template v-if="if_ordinary_misre">
          <errCard :title="'错误字词'" :errArray='info.ordinary_misre'></errCard>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import errCard from "./components/errCard.vue";
export default {
  components: {
    Breadcrumb,
    errCard
  },
  data() {
    return {
      routerList: ['排查功能', '分析详情'],
      info: null,//详情
      if_special_misre:false,//错误表述是否存在
      if_ordinary_misre:false,//错别字是否存在
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    console.log(params,'??');
    if (params) {
      this.info = params;
      // 判断排查类型
      if(params.special_misre.length>0){//错误表述
        this.if_special_misre = true
      }
      if(params.ordinary_misre.length>0){//错别字
        this.if_ordinary_misre = true
      }
      //判断是否有错误内容
      if (this.if_special_misre&&this.info.special_misre.length == 0) {
        this.info.special_misre = [
          { error: '无', correction: '无' }
        ]
      }
      if (this.if_ordinary_misre&&this.info.ordinary_misre.length == 0) {
        this.info.ordinary_misre = [
          { error: '无', correction: '无' }
        ]
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  width: 100%;
  height: 56px;
  background: #ffffff;
  position: fixed;
  margin-top: 0;
  // z-index: 100;
  position: relative;
  align-items: center;
  .head-left {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: center;
    img {
      height: 38px;
      width: 38px;
      margin-left: 23px;
      margin-right: 15px;
      cursor: pointer;
    }
    span {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  .img {
    position: absolute;
    right: 25px;
    height: 38px;
    // width: 38px;
    margin-left: 23px;
    margin-right: 15px;
    cursor: pointer;
  }
}
.page {
  .page-main {
    width: 100%;
    // height: 853px;
    margin-top: 24px;
    padding: 40px;
    background: #ffffff;
    border-radius: 4px;
    .page-main-title {
      position: relative;
      width: 100%;
      height: 130px;
      background: #ffffff;
      border: 1px solid #e4e6ec;
      padding: 40px 200px;
      img {
        position: absolute;
      }
      .zuoxia {
        left: 0;
        bottom: 0;
      }
      .youshang {
        top: 0;
        right: 0;
      }
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
      }
    }
    .page-main-content {
      padding: 40px 200px;
      text-indent: 2rem;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
    }
  }
}
</style>