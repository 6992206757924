<template>
  <div class="be-similar">
    <div class="be-similar-account">
      <span>{{title}}</span>
      <div class="be-similar-color"></div>
    </div>
    <div class="data-box">
      <div class="data-box-right">
        <div class="data-info" v-for="(item,idx) in errArray" :key="idx">
          <div class="subject-type">
            {{title}}：{{  item.error||"无" }}
          </div>
          <div class="region">
            建议更正：{{  item.correction||"无" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:'错误表述'
    },
    errArray:{
      type:Array,
      default:()=>[]
    }
  },
}
</script>

<style lang="scss" scoped>
.be-similar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 40px 200px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 16px;
  .be-similar-account {
    display: flex;
    align-items: center;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    span:nth-child(1) {
      z-index: 10;
    }
    span:nth-child(2) {
      font-size: 14px;
    }
    .be-similar-color {
      width: 77px;
      height: 6px;
      background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
      position: absolute;
      margin-left: 0px;
      margin-top: 22px;
      z-index: 9;
    }
  }
  .data-box {
    display: flex;
    width: 100%;
    height: auto;
    padding: 24px 0px;
    border-bottom: 1px solid #e4e6ec;
    .data-box-left {
      width: 104px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        height: 56px;
        width: 56px;
        border-radius: 50%;
      }
      .platform-type {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: auto;
        background: #f58030;
        max-width: 104px;
        padding: 0px 22px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 9px;
      }
    }
    .data-box-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      .name-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin-bottom: 16px;
        .name-box-left {
          display: flex;
          align-items: center;
          width: calc(100% - 170px);
          height: auto;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 24px;
            padding: 0px 8px;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .name {
            height: 22px;
            width: calc(100% - 200px);
            line-height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .time {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .bad-num {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          width: 20%;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e60b1e;
        }
      }
      .data-info {
        display: flex;
        height: 20px;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        .status {
          color: #02bc7c;
          margin-right: 46px;
        }
        .region {
          color: #02bc7c;
        }
        .subject-type {
          margin-right: 40px;
          color: #ea3342;
        }
        .company {
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 46px;
        }
        .fnas {
          margin-right: 46px;
        }
      }
    }
  }
}
</style>